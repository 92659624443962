<template>
  <div class="face">
    <div class="spin" v-if="loginLoading">
      <Spin size="large" fix />
    </div>
    <div class="cameraDisplay">
      <div class="faceGroup">
        <video id="faceVideo" width="150" height="150" preload autoplay loop muted></video>
        <canvas id="faceCanvas" width="150" height="150"></canvas>
      </div>
      <p>
        <Button
          id="faceStart"
          :disabled="loginLoading"
          type="primary"
          @click="faceTog"
        >{{isRec === false ? '开始识别' : '停止识别'}}</Button>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "faceLogin",
  data() {
    return {
      trackerTask: null,
      video: null,
      trackingObjects: null,
      trackTimeOut: null,
      capture: false,
      loginLoading: false,
      isRec: false,
    };
  },

  methods: {
    getTracking() {
      let trackingScript = document.createElement("script");
      trackingScript.setAttribute("type", "text/javascript");
      trackingScript.setAttribute("src", "plugin/face/tracking-min.js");
      trackingScript.onload = () => {
        let faceScript = document.createElement("script");
        faceScript.setAttribute("type", "text/javascript");
        faceScript.setAttribute("src", "plugin/face/face-min.js");
        document.body.appendChild(faceScript);
        faceScript.onload = this.actvieAll;
      };
      document.body.appendChild(trackingScript);
    },

    gotStream(stream) {
      let faceVideo = document.getElementById("faceVideo");
      try {
        faceVideo.srcObject = stream;
      } catch (e) {
        faceVideo.src = (window.URL || window.webkitURL).createObjectURL(
          stream
        );
      }
      faceVideo.onerror = function () {
        stream.stop();
      };
      stream.onended = this.noStream;
      faceVideo.onloadedmetadata = function () {
        console.log("摄像头打开成功！");
      };
    },

    noStream(err) {
      console.log(err || "摄像头打开失败！");
    },

    actvieCamera() {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        (navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
      if (navigator.getUserMedia) {
        navigator.getUserMedia({ video: true }, this.gotStream, this.noStream);
      } else {
        navigator.mediaDevices.getUserMedia(
          { video: true },
          this.gotStream,
          this.noStream
        );
      }
    },

    onStopTracking() {
      this.trackerTask.stop();
      this.video && this.video.pause();
    },

    onStartTracking(isCapture) {
      console.log(isCapture);
      console.log(this.video);
      this.video.play();
      this.trackerTask.run();
      if (isCapture === undefined) {
        this.capture = true;
      } else {
        this.capture = false;
      }
    },

    captureImage(x, y, width, height) {
      const buffer = 25;
      let video = document.getElementById("faceVideo");
      let bufferCanvas = document.createElement("canvas");
      let bfx = bufferCanvas.getContext("2d");
      bufferCanvas.width = width + buffer * 2;
      bufferCanvas.height = height + buffer * 2;
      bfx.drawImage(
        video,
        -x + buffer,
        -y + buffer,
        width + buffer * 2,
        height + buffer * 2
      );
      let url = bufferCanvas
        .toDataURL("image/png")
        .replace("data:image/png;base64,", "");
      this.faceLogin(url);
    },

    faceLogin(url) {
      this.loginLoading = true;
      this.$post(this.$api.USER.FACE_RECON, {
        apiType: "open",
        url,
      })
        .then((resp) => {
          this.clearFace();
          localStorage.setItem("userInfo", JSON.stringify(resp));
          this.$store.commit("setUser", resp);
          this.$router.push({ name: "main" });
        })
        .catch((d) => {
          this.loginLoading = false;
          this.isRec = false;
          this.onStartTracking(false);
        });
    },

    activeFace() {
      let canvas = document.getElementById("faceCanvas");
      let context = canvas.getContext("2d");
      this.trackingObjects = new window.tracking.ObjectTracker("face");
      this.trackingObjects.on("track", (e) => {
        if (canvas.clientWidth === 0) return;
        context.clearRect(0, 0, canvas.clientWidth, canvas.height);
        if (e.data.length === 1) {
          let r = e.data[0];
          context.strokeStyle = "#00b477";
          context.strokeRect(r.x, r.y, r.width, r.height);
          if (this.capture) {
            this.isRec = true;
            this.capture = false;
            this.onStopTracking(this.trackerTask, this.video);
            this.captureImage(r.x, r.y, r.width, r.height);
            return;
          }
        }
      });
      this.video = document.getElementById("faceVideo");
      this.trackerTask = window.tracking.track(
        "#faceVideo",
        this.trackingObjects
      );
    },

    actvieAll() {
      this.actvieCamera();
      this.activeFace();
    },

    clearFace() {
      this.trackerTask && this.trackerTask.stop();
      this.video&&this.video.pause();
      if (this.video&&this.video.srcObject.getVideoTracks().length > 0) {
        this.video.srcObject.getVideoTracks().forEach((videoTrack) => {
          videoTrack.stop();
        });
      }
      this.trackerTask = null;
      this.video = null;
      this.trackingObjects = null;
      this.trackTimeOut = null;
      this.capture = false;
    },

    faceTog() {
      if (!this.capture) {
        this.capture = true;
        this.isRec = true;
        // this.activeFace();
      } else {
        this.capture = false;
        this.isRec = false;
      }
    },
  },

  beforeDestroy() {
    this.clearFace();
  },

  mounted() {
    if (!window.tracking) this.getTracking();
    else this.actvieAll();
  },
};
</script>
<style lang="less" scoped>
.face {
  height: 170px;
  position: relative;
}
.spin {
  height: 150px;
  width: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  padding-top: 70px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
}
.cameraDisplay {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 10px;
  .faceGroup {
    width: 150px;
    height: 150px;
    position: relative;
    canvas {
      position: absolute;
      top: 0;
      left: 0;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  p {
    text-align: center;
    margin: 10px 0;
  }
}
</style>
<template>
  <div>
    <div id="wechat_iframe" class="wxIframe"></div>
    <!-- <p style="margin-top: 10px">使用微信扫描二维码登录</p> -->
    <Modal
      v-model="modalShow"
      title="绑定帐号"
      :closable="false"
      :mask-closable="false"
    >
      <p class="bingoTitle">验证成功！</p>
      <p class="bingoMsg">系统检测到您的微信<span>{{wxNick}}</span>是第一次登录本系统，<br/>请先绑定到相关帐号！</p>
      <Form
        :model="modalData"
        ref="bingoForm"
        :rules="bingoRules"
        style="width: 60%;margin: auto;margin-top: 10px;"
      >
        <FormItem prop="userName">
          <Input
            class="logininput"
            prefix="ios-contact"
            placeholder="请输入用户名"
            v-model="modalData.userName"
          />
        </FormItem>
        <FormItem prop="passWord">
          <Input
            class="logininput"
            prefix="md-lock"
            password
            v-model="modalData.passWord"
            placeholder="请输入密码"
            type="password"
          />
        </FormItem>
      </Form>
      <p slot="footer">
        <Button type="primary" @click="bingo" :loading="wechatLoading">绑定</Button>
      </p>
    </Modal>
  </div>
</template>
<script>
export default {
  name: 'wechatLogin',
  props: {
    /* wechatInfo: {
      type: String
    }, */
    wechatLoading: {
      type: Boolean
    }
  },
  computed: {
    modalShow () {
      return this.wechatInfo ? true : false;
    },

    wxNick () {
      return this.wechatInfo ? JSON.parse(JSON.parse(this.wechatInfo).bindInfo).nickname : '--'
    }
  },
  data () {
    return {
      modalData: {},
      wechatInfo: '',
      bingoRules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur"
          }
        ],
        passWord: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ]
      },
    }
  },

  methods: {
    getWechat () {
      let wxlScript = document.createElement('script');
      wxlScript.setAttribute('type', 'text/javascript');
      wxlScript.setAttribute('src', 'http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js');
      wxlScript.onload = this.initWx;
      document.body.appendChild(wxlScript);
    },

    initWx () {
      new window.WxLogin({
        id: "wechat_iframe",
        appid: "wx0b9f57aabb66ea29",
        scope: "snsapi_login",
        redirect_uri: encodeURI("http://gyl.sidwit.com/html/wxl.html"),
        state: "",
        style: "black", 
        href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjEwcHg7fQ0KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lfQ0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gDQouaW1wb3dlckJveCAuc3RhdHVzX2Jyb3dzZXIgcDpsYXN0LWNoaWxkIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZTt9',
        self_redirect: true,
      })
    },

    wxLgMessage (e) {
      if (e.data.code === 'wx_bing') return this.loginWxSuc(e.data.userInfo)
      if (e.data.code === 'wx_not_bing') return this.wxBing(e.data.wxBingInfo)
      if (e.data.code === 'error') {
        this.$Message.error({
          content: e.data.message || '请稍候再试！',
          duration: 3
        })
      }
    },

    loginWxSuc (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      this.$store.commit('setUser', userInfo);
      this.$router.push({ name: 'main' });
    },

    wxBing (wechatInfo) {
      this.wechatInfo = wechatInfo;
    },

    bingo () {
      this.$emit('dataChange', {...this.modalData});
      this.$emit('login')
    }
  },

  beforeDestroy () {
    window.removeEventListener('message', this.wxLgMessage)
  },

  mounted () {
    if (!window.WxLogin) this.getWechat();
    else this.initWx();
    window.addEventListener('message', this.wxLgMessage)
  }
}
</script>
<style lang="less" scoped>
.bingoTitle {
  text-align: center;
  font-size: 16px;
  color: #80BFDF;
  margin-bottom: 10px;
}
.bingoMsg {
  text-align: center;
  span {
    color: #80BFDF;
  }
}
</style>
<style lang="less">
.wxIframe {
  height: 280px;
  display: flex;
  justify-content: center;
  iframe {
    pointer-events: none;
  }
}
</style>
const CU = {
    // 查找树形结构获得指定子内容
    searchInTreeData(list = [], options) {
        let key = options?.key ?? 'id';
        let value = options?.value ?? '';
        let children = options?.children ?? 'childList';
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if ((item[key] + '') === (value + '')) {
                return item;
            }
            if (item[children] && item[children].length > 0) {
                let subresult = CU.searchInTreeData(item[children], options)
                if (subresult) return subresult;
            }
        }
        return null;
    },
    //验证数字
    validateNumber(value, min = 0, max, fixed = 2) {
        if (!value && value !== 0) return false;
        let patternString = fixed ? `[-|+]?\\d+(\\.\\d{0,${fixed}})?` : `[-|+]?\\d+`;
        let reg = new RegExp(patternString);
        let fitResult = (value + '').match(reg);
        if (!fitResult) return false;
        if (fitResult[0].length !== (value + '').length) return false;
        if ((min || min === 0) && (+value) < min) return false;
        if (max && (+value) > max) return false;
        return true;
    },
    //根据最终结果查找Cascader结构value数组
    findCascaderValue(data = [], value) {
        let result = [];
        data.forEach(item => {
            if (result.length !== 0) return;
            if (item.value + '' === value + '') {
                result.push(item.value);
            } else {
                if (item.children && item.children.length > 0) {
                    let subResult = CU.findCascaderValue(item.children, value);
                    if (subResult.length > 0) {
                        result = [item.value, ...subResult];
                    }
                }
            }
        });
        return result;
    },
    //格式化数据为cascader组件树形结构
    formatTreeData(data = [], valueKey = 'id', labelKey = 'name', childrenKey = 'childList', retainKeys = []) {
        let result = data.map(item => {
            let itemResult = {
                value: item[valueKey],
                label: item[labelKey]
            };
            retainKeys.forEach(key => {
                itemResult[key] = item[key]
            })
            if (item[childrenKey] && Array.isArray(item[childrenKey]) && item[childrenKey].length > 0) {
                itemResult.children = CU.formatTreeData(item[childrenKey], valueKey, labelKey, childrenKey, retainKeys);
            }
            return itemResult;
        });
        return result
    },
    // 格式化数据为树形组件的格式
    formatTreeComponentData(data = [], title_key = "name", children_key = 'childList', delete_origin = true, default_expand = () => true, default_checked = () => false) {
        data.forEach((item, key) => {
            item.title = item[title_key];
            item[children_key] && (item.children = CU.formatTreeComponentData(item[children_key], title_key, children_key, delete_origin, default_expand, default_checked));
            default_expand(item) && (key === 0 ? item.expand = true : item.expand = false);
            default_checked(item) && (item.checked = true);
            if (delete_origin) {
                delete item[title_key];
                if (children_key !== 'children') {
                    delete item[children_key];
                }
            }
        })
        return data;
    },
    // 遍历树形接口,执行指定操作
    forEachTree(data, func, child_string = 'childList') {
        if(!data)return;
        data.forEach((item) => {
            func(item);
            if (item[child_string]) {
                CU.forEachTree(item[child_string], func, child_string);
            }
        })
    },
    /**
   * 导出Excel
   * columns 表格列名
   * data 表格数据 类型为二维数组
   * name 表格名称
   */
    // exportExcel(columns, data, name) {
    //     let ws_data = [columns, ...data];
    //     let ws = XLSX.utils.aoa_to_sheet(ws_data);
    //     let wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, name);
    //     XLSX.writeFile(wb, `${name}.xlsx`);
    // },


    //根据id查找分类名称
    findClassName(data, id, value_key = "id", label_key = "name", children_key = 'childList') {
        // debugger;
        let result = ''
        data.forEach(item => {
            if (item[value_key] === id) {
                result = item[label_key]
            } else if (item[children_key] && item[children_key].length > 0) {
                let subResult = CU.findClassName(item[children_key], id, value_key, label_key, children_key)
                if (subResult.length > 0) {
                    result = subResult
                }
            }
        })
        return result
    },

    //树形结构只展开一个节点
    expandOne(treeData, pid, id, pid_key = "pid", id_key = "id") {
        treeData.forEach((item) => {
            if (String(pid) === String(item[pid_key]) && String(item[id_key]) !== String(id)) {
                item.expand = false;
            } else if (item.children && item.children.length > 0) {
                CU.expandOne(item.children, pid, id, pid_key, id_key);
            }
        });
        return treeData;
    },

    // 数组去重
    distinct(arr) {
        return [...new Set(arr)];
    },

    //验证手机号码
    validatePhone(value) {
        if (!value) return 0;
        let rule = /^1[3|4|5|7|8|9][0-9]{9}$/;
        let result = (value + '').match(rule);
        if (!result) return false;
        if (result[0].length !== (value + '').length) return false;
        return true;
    },

    //验证座机号码
    validateLandline(value) {
        if (!value) return 0;
        let rule = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
        let result = (value + '').match(rule);
        if (!result) return 0;
        if (result[0].length !== (value + '').length) return 0;
        return 1;
    },

    //计算时间
    calculatingTime(value) {
        if (value < 60 && value >= 0) {
            return value ? value + "秒" : "";
        } else if (value >= 60 && value < 60 * 60) {
            let second = value % 60;
            let minute = (value - second) / 60;
            return minute + "分" + (second ? second + "秒" : "");
        } else {
            let second = (value % 60) % 60;
            let minute = ((value - second) / 60) % 60;
            let hour = (value - minute * 60 - second) / 60 / 60;
            return hour + "小时" + minute + "分" + (second ? second + "秒" : "");
        }
    }
}

export function getRandomId(count = 6) {
  let str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_';
  let rstr = ''
  for (let i = 0; i < count; i++) {
    let pos = Math.round(Math.random() * str.length - 1);
    rstr += str[pos]
  }
  return rstr;
}
export default CU;